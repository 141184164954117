import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'

export default {
  name: 'DeletedFile',
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    close () {
      this.multimediaDirectories.resetDeleteFile()
    },
    deletedFile () {
      this.loading = true
      if (this.rootDirectory() === 'papelera') {
        const params = {
          path: this.multimediaDirectories.deletedFile.path,
          type: this.multimediaDirectories.deletedFile.type,
          fileName: this.multimediaDirectories.deletedFile.name,
          fileId: this.multimediaDirectories.deletedFile.fileId,
        }
        MultimediaService.deletedFile(params).then(
          (response) => {
            this.close()

            this.multimediaPreview.reset()
            this.multimediaDirectories.loadData(response.multimediaDirectories)
            const split = this.currentDirectory.path.split('/')
            let directory = this.multimediaDirectories.directories[split[0]]
            for (let i = 1; i < split.length; i++) {
              directory = directory.directories[split[i]]
            }
            this.currentDirectory.loadData(directory)

            this.fileQuota.loadData(response.fileQuota)

            EventBus.$emit('showAlert', 'success', this.$t(response.code))
          },
          (error) => {
            EventBus.$emit('showAlert', 'warning', error)
          },
        )
        .finally(() => {
          this.loading = false
        })
      } else {
        const params = {
          path: this.multimediaDirectories.deletedFile.path,
          newPath: this.multimediaDirectories.deletedFile.newPath,
          fileName: this.multimediaDirectories.deletedFile.name,
          fileId: this.multimediaDirectories.deletedFile.fileId,
        }
        MultimediaService.moveFile(params).then(
          (response) => {
            this.close()

            this.multimediaPreview.reset()
            this.multimediaDirectories.loadData(response.multimediaDirectories)
            const split = this.currentDirectory.path.split('/')
            let directory = this.multimediaDirectories.directories[split[0]]
            for (let i = 1; i < split.length; i++) {
              directory = directory.directories[split[i]]
            }
            this.currentDirectory.loadData(directory)

            EventBus.$emit('showAlert', 'success', this.$t(response.code))
          },
          (error) => {
            EventBus.$emit('showAlert', 'warning', error)
          },
        )
        .finally(() => {
          this.loading = false
        })
      }
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
  },
}
