import EventBus from '@/util/EventBus.js'

export default {
  name: 'ButtonMultimedia',
  props: {
    buttonText: { type: String, default: 'Multimedia' },
    currentRootDirectory: { type: String, default: 'imagenes' },
    openGlobal: { type: Boolean, default: false },
    icon: { type: Boolean, default: false },
    iconClass: { type: String, default: '' },
    directories: {
      type: Array,
      default: () => [''],
    },
  },
  methods: {
    showMultimedia () {
      EventBus.$emit('showMultimedia', this.currentRootDirectory, this.openGlobal, this.directories)
    },
    selectedMultimediaFile () {
      EventBus.$on('multimediaSelected', (url, type, fileId, templateName, fileInfo) => {
        if (type === 'csv') {
          this.$emit('onSelectedCsv', url, fileInfo)
        } else {
          this.$emit('onSelectedMultimediaFile', url, fileInfo)
        }
      })
    },
  },
  mounted () {
    this.selectedMultimediaFile()
  },
  beforeDestroy () {
    // EventBus.$off('multimediaSelected')
  },
}
