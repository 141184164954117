import EventBus from '@/util/EventBus.js'
import TextToSpeechSource from '../TextToSpeechSource/TextToSpeechSource.vue'
import MenuAction from '../MenuAction/MenuAction.vue'
import Key from '../Key/Key.vue'
import PhonesWithPrefix from '@/components/PhonesWithPrefix/PhonesWithPrefix.vue'
import { AvailableKeys } from '@/models/voice/AvailableKeys'
import { MenuOption } from '@/models/voice/MenuOption'
import { MenuOptionType, MenuAction as MenuActionClass } from '@/models/voice/MenuOptionType'
import { Menu } from '@/models/voice/Menu'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import Info from '@/components/Info/Info.vue'
import { CurrentSms } from '@/classes/CurrentSms'
import Senders from '@/components/FormConfiguration/Senders.vue'
import Sms from '@/components/Sms/Sms.vue'
import MessagePreview from '@/components/Sms/MessagePreview/MessagePreview.vue'
import PricesByCountry from '@/components/Modals/PricesByCountry/PricesByCountry.vue'
import { SmsObject } from '@/classes/SmsObject'
import { SmsRegExp } from '@/classes/SmsRegExp'

export default {
  props: [
    'componentsData',
    'isSubMenuComponent',
    'synthesisOptions',
    'menu',
    'user',
    'isPreview',
    'campaign',
    'fields',
    'isTemplate',
    'smsPreview',
    'sectionItems',
    'initSteps',
    'totalSteps',
    'isSectionHelper',
    'saveBarProperties',
  ],
  name: 'MenuOptions',
  components: { TextToSpeechSource, PhonesWithPrefix, MenuAction, Key, Info, Senders, Sms, MessagePreview, PricesByCountry },
  data: function () {
    return {
      availableKeys: new AvailableKeys(),
      currentMenuOption: this.createMenuOption(),
      phonesWithPrefix: new PhonesWithPrefixClass(),
      showForm: true,
      optionsCount: 0,
      currentSms: new CurrentSms('GSM', 0, 1, 0),
      alerts: {
        smsLength: {
          show: false,
          textContent: this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm" target="_blank">caracteres especiales</a> (tildes, diéresis) tu SMS no puede tener más de 70 caracteres.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres mantener los 160 caracteres pulsa <b>Restaurar</b>.'),
          buttonText: this.$t('Eliminar caracteres especiales').toString(),
          type: 'warning',
        },
      },
      regex: SmsRegExp.getRegExp(),
      smsObject: new SmsObject(),
      initStepsNumber: this.initSteps ? this.initSteps : 1,
      totalStepsNumber: this.totalSteps ? this.totalSteps : 3,
      isSectionMenu: this.isSectionHelper ? this.isSectionHelper : false,
      dialogCreateSection: false,
      tempSectionName: '',
    }
  },
  watch: {
    'currentMenuOption.typeId': function (newValue, oldValue) {
      this.$emit('on-editing-action', newValue && newValue !== undefined)
    },
  },
  computed: {
    existsSectionName: function () {
      const index = this.sectionItems.options.findIndex(item => item.name.toLowerCase() === this.tempSectionName.toLowerCase())
      return index !== -1
    },

    sectionItemsWithoutCurrentSection () {
      return this.sectionItems.options.filter(item => item.id !== this.menu.id)
    },

    menuActions: function () {
      return [
        new MenuActionClass(MenuOptionType.ADD_TO_BLACK_LIST, this.$t('Añadir a Blacklist').toString(), 'fas fa-user-slash'),
        new MenuActionClass(MenuOptionType.PLAY, this.$t('Reproducir mensaje').toString(), 'fa fa-volume-up'),
        new MenuActionClass(MenuOptionType.PLAY_AND_SEND_SMS, this.$t('Enviar SMS').toString(), 'fas fa-sms'),
        new MenuActionClass(MenuOptionType.CALL_TRANSFER, this.$t('Transferir llamada').toString(), 'fa fa-phone-volume'),
        new MenuActionClass(MenuOptionType.REPEAT_MESSAGE, this.$t('Repetir mensaje').toString(), 'fas fa-redo'),
      ]
    },
    dataWhilePlayingMenuAction () {
      return new MenuActionClass(MenuOptionType.GET_DATA_WHILE_PLAYING, this.$t('Ir a sección').toString(), 'fas fa-random')
    },
  },
  methods: {
    preCreateNewSection () {
      this.tempSectionName = ''
      this.dialogCreateSection = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.tempSectionName.$refs.input.focus()
        })
      })
    },

    createNewSection () {
      this.dialogCreateSection = false
      this.$emit('create-new-section', this.tempSectionName)
      setTimeout(() => {
        this.currentMenuOption.goToSection = this.sectionItems.options[this.sectionItems.options.length - 1].id
      }, 300)
    },

    updatedSmsObject () {
      this.currentMenuOption.data.setDataFromSmsObject(this.smsObject)
    },

    resetFormData () {
      this.showForm = false
      setTimeout(() => {
        this.showForm = true
      })
    },
    getFriendlyMessage () {
      switch (this.currentMenuOption.typeId) {
        case MenuOptionType.ADD_TO_BLACK_LIST:
          return this.$t('Vas a').toString() + ' <b>' + this.$t('Añadir a Blacklist').toString() + '</b> ' + this.$t('puedes agregar una').toString() + ' <b>' + this.$t('locución').toString() + '</b>'
        case MenuOptionType.GET_DATA_WHILE_PLAYING:
          return this.$t('Vas a').toString() + ' <b>' + this.$t('Ir a sección').toString() + '</b>'
        case MenuOptionType.CALL_TRANSFER:
          return '<b>' + this.$t('Añade el teléfono').toString() + '</b> ' + this.$t('donde transferir la llamada y la').toString() + ' <b>' + this.$t('locución').toString() + '</b>'
        case MenuOptionType.REPEAT_MESSAGE:
          return this.$t('Vas a').toString() + ' <b>' + this.$t('Repetir mensajes').toString() + '</b>'
        case MenuOptionType.PLAY:
          return this.$t('Vas a').toString() + ' <b>' + this.$t('Reproducir un mensaje').toString() + '</b> ' + this.$t('puedes agregar una').toString() + ' <b>' + this.$t('locución').toString() + '</b>'
        case MenuOptionType.PLAY_AND_SEND_SMS:
          return '<b>' + this.$t('Escribe o selecciona el audio').toString() + '</b> ' + this.$t('que vas a').toString() + ' <b>' + this.$t('Reproducir').toString().toLowerCase() + '</b>'
      }
    },

    updateOptionsCount () {
      if (!this.isSubMenuComponent) {
        this.optionsCount = this.menu.menuOptions.length - 1
      }
    },
    isAvailableKey (key) {
      return this.availableKeys.keys.includes(key)
    },
    isUnavailableKey (key) {
      return !this.isAvailableKey(key)
    },
    showSubMenu (show) {
      this.$emit('show-sub-menu', show)
    },
    // eslint-disable-next-line max-lines-per-function
    setKey (key) {
      if (this.isAvailableKey(key)) {
        const currentKey = this.currentMenuOption.key
        this.currentMenuOption = this.createMenuOption()
        this.currentMenuOption.description = ''
        if (currentKey !== key) {
          this.currentMenuOption.key = key
        }
        this.phonesWithPrefix.reset()
        this.currentMenuOption.typeId = undefined
        this.updateChildMenuOptions()
        this.showSubMenu(false)
        this.smsObject.reset()
      } else {
        this.loadMenuOption(key)
        if (this.currentMenuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
          this.showSubMenu(true)
        } else {
          this.showSubMenu(false)
        }
        if (this.currentMenuOption.typeId === MenuOptionType.PLAY_AND_SEND_SMS) {
          this.smsObject.setDataFromSmsMenuOption(this.currentMenuOption.data)
          this.loadSmsPreview()
          // eslint-disable-next-line max-depth
          if (this.currentMenuOption.data.templateId) {
            EventBus.$emit('queryLandingFromSmsOption', this.currentMenuOption.data.templateId, this.currentMenuOption.data.templateStructure)
          }
        }
      }
      this.resetFormData()
    },
    clearIfIsAvailable (key) {
      if (this.isAvailableKey(key)) {
        this.currentMenuOption = this.createMenuOption()
        this.currentMenuOption.key = key
        this.resetFormData()
      }
    },
    createMenuOption () {
      if (this.user && this.phonesWithPrefix) {
        this.phonesWithPrefix.isoCode = this.user.isoCountry.toLowerCase() || this.user.isoTld.toLowerCase()
      }
      const menuOption = new MenuOption()
      if (this.synthesisOptions) {
        menuOption.data.gender = this.synthesisOptions.gender
        menuOption.data.language = this.synthesisOptions.language
      }
      menuOption.description = this.currentMenuOption ? this.currentMenuOption.description : ''
      return menuOption
    },
    // eslint-disable-next-line max-lines-per-function
    setAction (typeId) {
      const currentKey = this.currentMenuOption.key
      this.clearIfIsAvailable(currentKey)
      if (this.currentMenuOption.typeId === typeId) {
        this.currentMenuOption.typeId = undefined
        this.showSubMenu(false)
      } else {
        this.currentMenuOption.typeId = typeId
        if (this.currentMenuOption.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
          this.currentMenuOption.menu = new Menu()
          this.showSubMenu(true)
        }
        if (this.currentMenuOption.typeId !== MenuOptionType.GET_DATA_WHILE_PLAYING) {
          this.currentMenuOption.menu = undefined
          this.showSubMenu(false)
        }
      }
    },
    phonKeyOrderAscendent (a, b) {
      const aKey = parseInt(a.key)
      const bKey = parseInt(b.key)
      return aKey - bKey
    },
    update () {
      const menuOptionIndex = this.getMenuOptionIndexByKey(this.currentMenuOption.key)
      this.currentMenuOption.setGetDataWhilePlayingMenuData()
      this.menu.menuOptions[menuOptionIndex] = this.currentMenuOption.copy()
      this.updateParentMenuOptions()
      this.menu.menuOptions.sort(this.phonKeyOrderAscendent)
      this.currentMenuOption = this.createMenuOption()
      this.showSubMenu(false)
      this.emitUpdateMenuOptions()
    },
    add (menuOption) {
      menuOption.setGetDataWhilePlayingMenuData()
      this.menu.menuOptions.push(menuOption.copy())
      this.updateParentMenuOptions()
      this.availableKeys.removeKey(menuOption.key)
      this.menu.menuOptions.sort(this.phonKeyOrderAscendent)
      this.currentMenuOption = this.createMenuOption()
      this.showSubMenu(false)
      this.updateOptionsCount()
      this.emitUpdateMenuOptions()
    },
    emitUpdateMenuOptions () {
      setTimeout(() => {
        this.$emit('update-menu-options')
      })
    },
    save () {
      if (this.isUnavailableKey(this.currentMenuOption.key)) {
        this.update()
      } else {
        this.add(this.currentMenuOption)
      }
    },
    removeMenuOption () {
      this.menu.menuOptions.splice(this.getMenuOptionIndexByKey(this.currentMenuOption.key), 1)
      this.availableKeys.keys.push(this.currentMenuOption.key)
      this.availableKeys.keys.sort(this.phonKeyOrderAscendent)
      this.currentMenuOption = this.createMenuOption()
      this.updateParentMenuOptions()
      this.updateOptionsCount()
    },
    getMenuOptionIndexByKey (key) {
      const byKey = (menuOption) => menuOption.key === key
      return this.menu.menuOptions.findIndex(byKey)
    },
    getMenuOptionByKey (key) {
      const menuOptionIndex = this.getMenuOptionIndexByKey(key)
      return this.menu.menuOptions[menuOptionIndex]
    },
    updateParentMenuOptions () {
      if (this.isSubMenuComponent) {
        this.$root.$emit('update-parent-menu-options', this.menu.menuOptions)
      }
    },
    updateChildMenuOptions () {
      if (this.isSubMenuComponent) {
        return
      }
      if (this.currentMenuOption.typeId !== MenuOptionType.GET_DATA_WHILE_PLAYING) {
        this.$root.$emit('update-child-menu-options', [])
        return
      }
      this.$root.$emit('update-child-menu-options', this.currentMenuOption.menu.menuOptions)
    },
    loadMenuOption (key) {
      const option = this.getMenuOptionByKey(key)
      this.currentMenuOption = new MenuOption(option)
      if (option && option.typeId === MenuOptionType.CALL_TRANSFER) {
        this.phonesWithPrefix.setDataFromMenuOptionData(this.currentMenuOption.data)
      }
      if (option && option.typeId === MenuOptionType.GET_DATA_WHILE_PLAYING) {
        this.currentMenuOption.menu.menuOptions = option.menu.menuOptions
        this.updateChildMenuOptions()
      }
    },
    updateMenuOptionDataPhonesWithPrefix (phonesWithPrefix) {
      this.currentMenuOption.data.setDataFromPhonesWithPrefix(phonesWithPrefix)
      this.$forceUpdate()
    },
    setLanding (landing, landingTemplate) {
      this.$refs.smsComponent.addFieldToMessage('{LAND_URL}')
      this.campaign.sendings[0].channel.templateId = landing.id
      this.campaign.sendings[0].channel.templateStructure = landingTemplate.structure
      EventBus.$emit('updateLandingTemplateSmsOption', { isLandingMessage: true, showLanding: true, landing: landing, landingTemplate: landingTemplate })
    },
    clearVariables (text) {
      text = text.replace(this.regex.urlRegExp, this.regex.EXAMPLE_SHORTENED_URL)
      return text
    },
    showUnsubscribePreview () {
      EventBus.$emit('showUnsubscribePreviewFromSmsOption')
    },
    async showFormPreview (formId) {
      EventBus.$emit('showFormPreviewFromSmsOption', formId)
    },
    showLanding () {
      EventBus.$emit('showLandingFromSmsOption')
    },
    loadSmsPreview () {
      EventBus.$emit('contactSource:LoadAdditionalSmsPreview', this.campaign, this.smsObject, this.smsPreview)
    },
  },
  created () {
    this.availableKeys = new AvailableKeys()
    this.menu.menuOptions.forEach(menuOption => {
      // eslint-disable-next-line no-magic-numbers
      if (menuOption.key !== -1) this.availableKeys.removeKey(menuOption.key)
    })
    this.updateOptionsCount()
  },
  mounted () {
    EventBus.$on('changePreviewUploadResponseVoiceSms', () => {
      this.loadSmsPreview()
    })

    if (this.isSubMenuComponent) {
      this.$root.$on('update-child-menu-options', (menuOptions) => {
        this.menu.menuOptions = menuOptions
        this.availableKeys = new AvailableKeys()
        // eslint-disable-next-line max-nested-callbacks
        const keys = this.menu.menuOptions.map(menuOption => menuOption.key)
        this.availableKeys.removeKeys(keys)
        this.$forceUpdate()
      })
    }
    // parent
    if (!this.isSubMenuComponent) {
      // set the options to parent
      this.$root.$on('update-parent-menu-options', (menuOptions) => {
        this.currentMenuOption.menu.menuOptions = menuOptions
        this.$forceUpdate()
      })
    }
  },
}
