import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'
import ListDirectory from '../Common/ListDirectory/ListDirectory.vue'

export default {
  name: 'MoveFile',
  components: {
    ListDirectory,
  },
  props: {
    currentDirectory: { type: Object, require: true },
    fileQuota: { type: Object, require: true },
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
  },
  data () {
    return {
      show: false,
      loading: false,
      item: {
        path: '',
      },
    }
  },
  methods: {
    close () {
      this.multimediaDirectories.resetMove()
      this.removeDirectoryitemActive()
    },
    cleanItemPath () {
      this.item.newPath = ''
    },
    async moveFile () {
      this.validate = await this.validatenewPath()

      if (!this.validate.success) {
        EventBus.$emit('showAlert', 'warning', this.validate.message)
        return false
      }

      this.loading = true
      const params = {
        path: this.multimediaDirectories.move.path,
        newPath: this.multimediaDirectories.move.newPath,
        type: this.multimediaDirectories.move.type,
        fileName: this.multimediaDirectories.move.name,
        fileId: this.multimediaDirectories.move.fileId,
      }
      MultimediaService.moveFile(params).then(
        (response) => {
          this.close()

          this.multimediaPreview.reset()
          this.multimediaDirectories.loadData(response.multimediaDirectories)
          const split = this.currentDirectory.path.split('/')
          let directory = this.multimediaDirectories.directories[split[0]]
          for (let i = 1; i < split.length; i++) {
            directory = directory.directories[split[i]]
          }
          this.currentDirectory.loadData(directory)

          EventBus.$emit('showAlert', 'success', this.$t(response.code))
        },
        (error) => {
          EventBus.$emit('showAlert', 'warning', this.$t(error.response.data.code))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    removeDirectoryitemActive () {
      const elements = document.querySelectorAll('.directory__item')
      elements.forEach(function (elemen) {
        elemen.classList.remove('directory__item-active')
      })
    },
    rootDirectory () {
      return this.currentDirectory.path.split('/')[0]
    },
    originalDirectory () {
      return this.currentDirectory?.files[0]?.fileType
    },
    async validatenewPath () {
      if (this.multimediaDirectories.move.path === this.multimediaDirectories.move.newPath) {
        return {
          success: false,
          message: this.$t('No es posible seleccionar el directorio actual'),
        }
      }

      return {
        success: true,
        message: '',
      }
    },
  },
}
