import ListDirectory from '../Common/ListDirectory/ListDirectory.vue'
import EventBus from '@/util/EventBus.js'

export default {
  name: 'DirectoryMultimedia',
  components: {
    ListDirectory,
  },
  props: {
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
    fileQuota: { type: Object, require: true },
    currentDirectory: { type: Object, require: true },
    rootDirectories: { type: Object, require: true },
    openGlobal: { type: Boolean, require: true },
    sectionDirectories: { type: Array, require: true },
    sourceParams: { type: Object, required: true },
  },
  computed: {
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
    multimediaDirectoriesList () {
      // // <!-- App Preview Whatsapp -->
      // if (!this.canWhatsapp) {
      //   delete this.multimediaDirectories.directories.whatsapp
      // }
      return this.multimediaDirectories.directories
    },
  },
  methods: {
    clearDirectory () {
      this.sourceParams.source = 'library'
      this.multimediaPreview.show = false
      EventBus.$emit('MultimediaManager:setEmptySearch')
    },
  },
}
