import { CurrentSms } from '@/classes/CurrentSms'
import { SmsParser } from '@rtorralba/smsparser'
import EventBus from '@/util/EventBus.js'
import { Attachment } from '@/models/Attachment.js'
import LandingTemplateService from '@/services/landing-template.service'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import CampaignService from '@/services/campaign.service'
import Senders from '@/components/FormConfiguration/Senders.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import UsersUrlsService from '@/services/users-urls.service'
import CampaignDltValidationService from '@/services/campaign-dlt-validation.service'
import DltVariableSubstitutionDialog from '@/views/Pages/Tools/DLT/DltVariableSubstitutionDialog/DltVariableSubstitutionDialog.vue'


export default {
  name: 'Sms',
  props: {
    preview: {
      type: Object,
      required: true,
    },
    componentsData: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      required: false,
    },
    regex: {
      type: Object,
      required: true,
    },
    currentSmsIn: {
      type: Object,
      required: true,
    },
    smsObject: {
      type: Object,
      required: true,
    },
    isTemplate: {
      type: Boolean,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
    },
    campaign: {
      type: Object,
      required: false,
    },
    showLandingAction: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAttachmentAction: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: { EmojiPickerInput, Senders, UrlsDialog, DltVariableSubstitutionDialog },
  data: function () {
    return {
      currentSms: this.currentSmsIn,
      isLandingMessage: false,
      dropdownStyle: 'border-bottom: 1px solid #b7c5de; width:800px',
      alerts: {
        smsLength: {
          show: false,
          textContent: this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm" target="_blank">caracteres especiales</a> tu SMS causará un mayor impacto a tu BBDD y aumentará la eficacia de tu envío. El límite de caracteres por SMS se limitará a 70, tienes la posibilidad de enviar varios SMS concatenados si es necesario.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres no usarlos pulsa <b>Restaurar</b>.'),
          buttonText: this.$t('Eliminar caracteres especiales').toString(),
          type: 'warning',
        },
        smsLengthCustomFields: {
          show: false,
          textContent: '',
          type: 'info',
        },
        invalidCustomFields: {
          show: false,
          textContent: this.$t('El mensaje contiene campos personalizados inválidos').toString(),
          type: 'warning',
        },
      },
      sender: '',
      landing: { id: null, name: '', preview: '' },
      landingTemplate: new Landing(null),
      extraEncodings: [],
      showTooltip: false,
      MAX_MESSAGE_CHUNKS: 5,
      showAlertCharacters: false,
      isShortSms: false,
      chunkCountChange: false,
      pollsMenu: false,
      urlsDialog: false,
      loadingSendingCost: false,
      timeout: null,
      showEmoji: true,
      countryIsoValidateSms: [
        'IN', 'IND',
      ],
      showModalSendingSmsValidate: false,
      showModalDltVarSubstitution: false,
      selectedDltMessage: null,
      smsMessageValidates: [],
      messageValidateId: false,
      readySender: true,
    }
  },
  methods: {
    openAiAssistant () {
      EventBus.$emit('AiVoiceSmsAssistant:open')
    },
    openModalSendingSmsValidate () {
      this.showModalSendingSmsValidate = true
      this.getCampaignSendingSmsValidate()
    },
    closeModalSendingSmsValidate () {
      this.showModalSendingSmsValidate = false
    },
    getCampaignSendingSmsValidate () {
      CampaignDltValidationService.getApproved()
      .then((response) => {
        this.smsMessageValidates = response
      })
    },
    acceptedModalSendingSmsValidate () {
      setTimeout(() => {
        this.selectedDltMessage = this.smsMessageValidates.find(message => message.id === this.messageValidateId)
        if (this.dltVarFieldsCount > 0) {
          this.showModalDltVarSubstitution = true
        } else {
          this.selectValidatedMessage(this.selectedDltMessage)
        }
        this.closeModalSendingSmsValidate()
      })
    },
    selectValidatedMessage (messageObject) {
      this.smsObject.sender = messageObject.sender
      this.smsObject.message = messageObject.template_content
      this.parseSms()

      this.readySender = false
      setTimeout(() => {
        this.readySender = true
      })
    },
    acceptDltVariableSubstitution (message) {
      this.showModalDltVarSubstitution = false
      this.selectValidatedMessage(message)
    },
    updatedSmsObject () {
      this.$emit('updatedSmsObject', this.smsObject)
    },
    updateSender (sender) {
      this.smsObject.sender = sender
    },
    clearVariables (text) {
      text = text.replace(this.regex.variablesRegex, '')
      text = text.replace(this.regex.urlRegExp, this.regex.EXAMPLE_SHORTENED_URL)
      return text
    },
    validateIsClickable (message) {
      const expresion = message.match(/{LAND_URL}|{URL_[0-9]+}|{FORM_[0-9]+}|{ATTACHMENT_[0-9]+}|{UNSUB_URL}|{[\w-]+\s*\|\s*shorten}/)
      if (expresion === null) {
        this.smsObject.clickable = false
      } else {
        this.smsObject.clickable = true
      }
    },

    checkGSMPT (text) {
      const checkSmsParserResult = SmsParser.parse(this.clearVariables(text) + this.smsObject.optOut, 'GSM', [])
      if (this.currentSms.encoding === 'GSM-PT' && checkSmsParserResult.encoding === 'GSM') {
        this.currentSms.encoding = 'GSM'
      }
    },

    parseSms () {
      let current = null
      const text = this.smsObject.message
      this.checkGSMPT(text)
      let smsParserResult = SmsParser.parse(this.clearVariables(text) + this.smsObject.optOut, this.currentSms.encoding, this.extraEncodings)
      smsParserResult = this.checkAndLimitMessage(text, smsParserResult)
      current = this.smsObject.encoding
      if (current !== smsParserResult.encoding) {
        this.smsObject.encoding = smsParserResult.encoding
        if (this.smsObject.encoding !== this.currentSms.encoding) {
          this.alerts.smsLength.show = true
          this.alerts.smsLength.textContent = this.encodingIsGSMPT
            ? this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm-pt" target="_blank">caracteres especiales</a> tu SMS causará un mayor impacto a tu BBDD y aumentará la eficacia de tu envío. El límite de caracteres por SMS se limitará a 155, tienes la posibilidad de enviar varios SMS concatenados si es necesario.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres mantener los 160 caracteres pulsa <b>Restaurar</b>.')
            : this.$t('Si introduces <a class="link-primary" href="https://apidocs.360nrs.com/es/#conjunto-de-caracteres-gsm" target="_blank">caracteres especiales</a> (tildes, diéresis) tu SMS no puede tener más de 70 caracteres.<br><br>Si quieres usar caracteres especiales pulsa <b>Continuar</b>.<br>Si prefieres mantener los 160 caracteres pulsa <b>Restaurar</b>.')
        }
      }
      this.updateCurrentSms(smsParserResult)
      this.showAlerts()

      this.validateIsClickable(this.smsObject.message)
    },

    checkAndLimitMessage (originalText, smsParserResult) {
      if (smsParserResult.chunks <= this.componentsData.maxMessageChunks) {
        return smsParserResult
      }
      let limited
      if (this.smsObject.optOut.length) {
        originalText = this.smsObject.optOut + originalText
        limited = SmsParser.limitChunks(originalText, this.componentsData.maxMessageChunks, this.smsObject.encoding, this.smsObject.optOut.length)
        limited = limited.substring(this.smsObject.optOut.length)
      } else {
        limited = SmsParser.limitChunks(originalText, this.componentsData.maxMessageChunks, this.smsObject.encoding, this.smsObject.optOut.length)
      }
      this.smsObject.message = limited
      return SmsParser.parse(this.clearVariables(limited) + this.smsObject.optOut, this.smsObject.encoding, this.extraEncodings)
    },

    setCurrentSmsEncodingByCountryIso (countryIso) {
      if (['BR', 'PT', 'AO', 'MO', 'GQ', 'CV'].includes(countryIso)) {
        this.extraEncodings = ['GSM-PT']
        this.currentSms = new CurrentSms('GSM-PT', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
      } else {
        this.extraEncodings = []
        this.currentSms = new CurrentSms('GSM', this.currentSms.characters, this.currentSms.chunks, this.currentSms.maxCharactersInfo)
      }
      this.parseSms()
    },

    updateCurrentSms (smsParserResult) {
      this.currentSms = CurrentSms.makeFromSmsParserResult(this.currentSms.encoding, smsParserResult)
      this.validateShowEmoji(this.currentSms)
    },

    validateShowEmoji (currentSms) {
      const chunks = currentSms.chunks
      const resto = (currentSms.maxCharacters - (currentSms.characters))

      if (chunks >= 2 && (resto === 1 || resto === 0)) {
        this.showEmoji = false
      } else {
        this.showEmoji = true
      }
    },

    showAlerts () {
      this.alerts.smsLengthCustomFields.show = this.hasCustomFields(this.smsObject.message, this.regex.variablesRegex)
      this.alerts.invalidCustomFields.show = this.hasInvalidFields(this.smsObject.message)
    },

    smsLengthCustomFieldsMessage () {
      return this.$t('Ten en cuenta que si los campos personalizados utilizados en el contenido del mensaje superan') + '<b style="font-size: 16px;">' + ' ' + this.currentSms.maxCharactersInfo + '</b>' + ' ' + this.$t('caracteres, se generarán mensajes concatenados') + '.'
    },

    hasInvalidFields (text) {
      this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, text)
      this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, text)
      if (!this.fields.availableFields) {
        return false
      }
      const array = Object.keys(this.fields.availableFields)
      for (let i = 0; i < this.fields.currentFieldsMessage.length; i++) {
        if (array.indexOf(this.fields.currentFieldsMessage[i]) < 0) {
          return true
        }
      }
      return false
    },

    setCurrentFieldsMessageAndUrls (text) {
      this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, text)
      this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, text)
    },

    getCurrentFieldsArray (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match.substr(1, (match.length - 2)))
        match = regExp.exec(s)
      }
      return matches
    },

    getMatches (regExp, s) {
      let match = regExp.exec(s)
      const matches = []

      while (match != null) {
        match = match[0]
        matches.push(match)
        match = regExp.exec(s)
      }
      return matches
    },

    hasCustomFields (text, regExp) {
      let matches = []

      if (text) {
        matches = this.getMatches(regExp, text)
      }
      this.currentSmsIn.hasCustomFields = false
      if (matches.length > 0) {
        this.currentSms.maxCharactersInfo = this.currentSms.maxCharacters - this.currentSms.characters
        this.currentSmsIn.hasCustomFields = true
        this.alerts.smsLengthCustomFields.textContent = this.smsLengthCustomFieldsMessage()
        return true
      }
      return false
    },

    addEmojiToMessage (emoji) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.smsObject.message = before + emoji + after

      this.$nextTick().then(() => {
        textarea.selectionStart = textarea.value.length
        textarea.click()
        this.parseSms()
      })
    },

    // eslint-disable-next-line complexity
    addFieldToMessage (item, type) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        if (type === 'form') {
          fieldName = '{FORM_' + item + '}'
        }
        if (type === 'attachment') {
          fieldName = '{ATTACHMENT_' + item + '}'
        }
        if (type === 'url') {
          fieldName = '{URL_' + item + '}'
        }
        if (type === 'last-sms') {
          fieldName = this.componentsData.lastSms[item].message
        }
        if (type === 'bookmark') {
          fieldName = this.componentsData.bookmarks[item].message
        }
        if (type === 'smsOptionsBookmarks') {
          fieldName = this.componentsData.smsOptionsBookmarks[item].message
        }
        if (type === 'last-sms-options') {
          fieldName = this.componentsData.latestsSmsOptionsMessages[item].message
        }

        this.insertFieldAtCursor(fieldName)
        this.parseSms()
        this.fields.currentFieldsMessage = this.getCurrentFieldsArray(this.regex.variablesRegex, this.smsObject.message)
        this.fields.currentUrlFieldsMessage = this.getCurrentFieldsArray(this.regex.urlRegExp, this.smsObject.message)
        this.showAlerts()
      }
    },

    toggleBookMarks () {
      this.smsObject.bookmark = !this.smsObject.bookmark
    },

    insertFieldAtCursor (myValue) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      if (myValue === '{UNSUB_URL}' && sentence.includes(myValue)) return
      if (!myValue.length) return

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.smsObject.message = before + myValue + after

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + myValue.length
        textarea.click()
      })
    },

    removeSpecialChars () {
      this.smsObject.message = SmsParser.substitute(this.smsObject.message, this.smsObject.encoding, this.currentSms.encoding).message
      this.smsObject.encoding = this.currentSms.encoding
      this.alerts.smsLength.show = false
      this.removeEmojis()
      return this.parseSms()
    },

    removeEmojis () {
      this.smsObject.message = this.smsObject.message.replace('??', '')
    },

    acceptSpecialChars () {
      this.alerts.smsLength.show = false
      return this.parseSms()
    },

    addBookmark () {
      if (!this.smsObject.message) {
        return
      }
      this.smsObject.bookmark = this.smsObject.bookmark === 1 ? 0 : 1
    },

    showModalPrices () {
      EventBus.$emit('showModalPrices', this.currentSms)
      // this.$emit('onRefreshPreview')
    },

    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },

    async selectLanding (url) {
      const path = url.substring(url.indexOf('=') + 1)
      const data = { path: path }
      LandingTemplateService.getLandingByPath(data)
        .then(
          (response) => {
            this.landing.id = response.id
            this.landing.name = response.name
            this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
            this.landingTemplate.structure = JSON.parse(response.structure)
            this.landingTemplate.customVars = this.fields.availableFields
            this.smsObject.templateId = this.landing.id
            this.smsObject.templateStructure = this.landingTemplate.structure
            this.landingTemplate.setEditorHelper()
            this.$emit('onSelectedLanding', this.landing, this.landingTemplate)
          },
          () => { },
        )
    },

    selectAttachment (url, fileId) {
      const attachment = new Attachment({ id: fileId, url: url })
      const exists = this.smsObject.attachments.filter((attachmentIn) => {
        return attachmentIn.id === attachment.id
      })
      if (exists.length === 0) {
        this.smsObject.attachments.push(attachment)
      }

      this.addFieldToMessage(fileId, 'attachment')
    },

    closeUrlsDialog () {
      this.urlsDialog = false
    },
    onLoading (value) {
      EventBus.$emit('showLoading', value)
    },
    reloadUrls () {
      UsersUrlsService.getFormAvailableUrls()
      .then(
        (response) => {
          this.componentsData.availableUrls = response
        },
        () => {},
      )
      .finally(() => {

      })
    },
    setLoading: function (isLoading = false) {
      this.loadingSendingCost = isLoading
    },
  },
  watch: {
    smsObject: {
      handler () {
        this.updatedSmsObject()
      },
      deep: true,
    },
    senderStatus () {
      // this.sender = this.campaign.sendings[0].channel.sender
    },
    componentStatus () {
      this.parseSms()
    },
    countryIso () {
      const data = { countryIso: this.smsObject.countryIso }
      CampaignService.getOptOut(data)
        .then(
          (response) => {
            this.smsObject.optOut = response.length ? ' ' + response : response
            this.parseSms()
          },
        )
    },
    'currentSms.chunks': function (value) {
      this.chunkCountChange = true
      setTimeout(() => {
        this.chunkCountChange = false
      }, 500)
      this.currentSmsIn.chunks = value
    },
    messageWatcher: function () {
      if (this.timeout !== null) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        this.$emit('onRefreshPreview')
      }, 2000)
    },
  },
  computed: {
    dltVarFieldsCount () {
      return (this.selectedDltMessage.template_content.match(/\{#.+?#\}/g) || []).length
    },
    fieldsAsArray () {
      const fields = []
      for (const key in this.fields.availableFields) {
        fields.push({ value: key, text: this.fields.availableFields[key] })
      }
      return fields
    },
    isValidDlt: function () {
      const user = this.$store.getters['auth/getUser']
      const isoCountry = user.isoCountry?.toUpperCase() || user.isoTld?.toUpperCase()
      return this.countryIsoValidateSms.includes(isoCountry) && this.countryIsoValidateSms.includes(this.campaign.countryIso)
    },
    messageWatcher () {
      return this.smsObject.message
    },
    senderStatus () {
      return this.sender
      // this.campaign.sendings[0].channel.sender !== ''
    },
    componentStatus () {
      return this.componentsData.maxMessageChunks !== null
    },
    senders: function () {
      const senderList = []
      const that = this
      if (this.componentsData.previousSenders) {
        this.componentsData.previousSenders.map(function (item, index) {
          senderList.push({
            type: that.$t('Últimos remitentes').toString(),
            text: item,
            value: 'last' + index,
          })
        })
      }
      if (this.componentsData.twoWaySenders) {
        this.componentsData.twoWaySenders.map(function (item, index) {
          senderList.push({
            type: 'TwoWay',
            text: item,
            value: 'TwoWay' + index,
          })
        })
      }
      if (this.componentsData.twoWayDedicatedSenders) {
        this.componentsData.twoWayDedicatedSenders.map(function (item, index) {
          senderList.push({
            type: 'TwoWay ' + that.$t('Dedicado').toString(),
            text: item,
            value: 'dedicado' + index,
          })
        })
      }
      if (this.componentsData.verifiedSenders) {
        this.componentsData.verifiedSenders.map(function (item, index) {
          senderList.push({
            type: that.$t('Verificado').toString(),
            text: item,
            value: 'verified' + index,
          })
        })
      }
      return senderList
    },
    isUTF16 () {
      return this.smsObject.encoding !== this.currentSms.encoding
    },
    encodingIsUTF16 () {
      return this.smsObject.encoding === 'UTF-16'
    },
    encodingIsGSMPT () {
      return this.smsObject.encoding === 'GSM-PT'
    },
    encodingIsNotGSM () {
      return this.smsObject.encoding !== 'GSM'
    },
    currentEncoding () {
      return this.smsObject.encoding
    },
    canUseCustomSender () {
      if (['CO', 'BR'].indexOf(this.smsObject.countryIso) < 0) {
        return true
      }
      // this.campaign.sendings[0].channel.sender = 'Test'
      this.sender = 'Test'
      this.smsObject.sender = 'Test'
      return false
    },
  },
  created () {
    this.setCurrentFieldsMessageAndUrls(this.smsObject.message)
    setTimeout(() => {
      this.$emit('onRefreshPreview')
    }, 1000)
  },
  mounted () {
    // this.sender = this.campaign.sendings[0].channel.sender
    EventBus.$on('multimediaSelected', (url, type, fileId) => {
      console.log('type: ', type) // No quitar por alguna razon activa el evento hay que revisarlo
      if (type === 'csv') {
        return
      }

      if (type === 'landings') {
        this.selectLanding(url)
      }

      if (type === 'attachment') {
        this.selectAttachment(url, fileId)
      }

      if (type === 'form') {
        this.addFieldToMessage(fileId, 'form')
      }
    })
    EventBus.$on('loadingSendingCost', this.setLoading)
    EventBus.$on('Campaign:changeCountryIso', this.setCurrentSmsEncodingByCountryIso)
    if (this.campaign) {
      this.setCurrentSmsEncodingByCountryIso(this.campaign.sendings[0].csvFileConfig.countryIso)
    }

    EventBus.$on('AiVoiceSmsAssistant:sendIdea', (idea) => {
      this.smsObject.message += idea
      this.parseSms()
    })
  },
  unmounted () {
    EventBus.$off('Campaign:changeCountryIso')
    EventBus.$off('multimediaSelected')
    EventBus.$off('loadingSendingCost', this.setLoading)
  },
}
