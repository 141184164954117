import { render, staticRenderFns } from "./Senders.vue?vue&type=template&id=6f7e6efc&scoped=true&"
import script from "./Senders.js?vue&type=script&lang=js&"
export * from "./Senders.js?vue&type=script&lang=js&"
import style0 from "./Senders.vue?vue&type=style&index=0&id=6f7e6efc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7e6efc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCombobox,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VSelect})
