var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('Remitente'),"rules":{
      required: true,
      max: _vm.senderMaxLength,
      regex: _vm.REGEX_SENDER,
    },"mode":"aggressive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-combobox',{directives:[{name:"show",rawName:"v-show",value:(_vm.canUseCustomSender),expression:"canUseCustomSender"}],ref:"sendercombobox",staticClass:"sender-input",attrs:{"id":"sender","disabled":_vm.disableComboBoxIn,"readonly":_vm.isValidDlt,"items":_vm.senders,"label":_vm.$t('Remitente'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":"","maxlength":_vm.senderMaxLength,"counter":_vm.senderMaxLength,"error-messages":errors,"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.formatSender(_vm.search)},"change":function($event){return _vm.formatSender(_vm.sender)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',{attrs:{"id":item.text}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-subtitle',[_c('small',[_vm._v(_vm._s(item.type))])])],1)]}}],null,true),model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canUseCustomSender),expression:"!canUseCustomSender"}],ref:"sender",staticClass:"sender-input",attrs:{"id":"sender","name":"sender","items":_vm.senders,"label":_vm.$t('Remitente'),"filled":"","color":"light-blue darken-2","background-color":"blue-grey lighten-5","required":"","disabled":_vm.disableComboBoxIn,"item-value":"text","item-text":"text"},on:{"change":function($event){return _vm.formatSender(_vm.sender)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',{attrs:{"id":item.text}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),_c('v-list-item-subtitle',[_c('small',[_vm._v(_vm._s(item.type))])])],1)]}}],null,true),model:{value:(_vm.sender),callback:function ($$v) {_vm.sender=$$v},expression:"sender"}})]}}])}),(_vm.groupHasNotCustomSenderCountries || !_vm.canUseCustomSender)?_c('span',{staticClass:"mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pb-0"},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"#04ace6"}},[_vm._v(" fas fa-info-circle ")]),_c('span',{staticClass:"info-text"},_vm._l((_vm.selectedCountries),function(country){return _c('img',{key:country,staticClass:"info-flag mr-2",attrs:{"src":country}})}),0)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col pt-0 pe-0 pb-0"},[_c('small',[_vm._v(_vm._s(_vm.$t('Las operadoras asignan el remitente automáticamente excepto en números twoway.')))])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }