import EventBus from '@/util/EventBus.js'
import VoicePreview from '@/components/Template/Voice/VoicePreview/VoicePreview.vue'
import LandingTemplateService from '@/services/landing-template.service.js'
import CampaignVoiceService from '@/services/campaign-voice.service'
import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType'
import { TextFormatParser } from '@/classes/TextFormatParser'

export default {
  name: 'PreviewMultimedia',
  components: {
    LandingTemplateService,
    VoicePreview,
  },
  props: {
    multimediaPreview: { type: Object, required: true },
    sourceParams: { type: Object, required: true },
  },
  data () {
    return {
      voiceTemplate: null,
      voicePayload: null,
      htmlLanding: null,
      reloadPreview: true,
      headboardTypes: {
        TEXT: 1,
        HEADER_TEXT: 2,
        CALL_ACTION_TEXT: 3,
        CALL_ACTION_HEADER_TEXT: 4,
        RESPONSE_TEXT: 5,
        RESPONSE_HEADER_TEXT: 6,
        CALL_ACTION_TEXT_AUTH: 7,
        OPTION_TEXT: 8,
        OPTION_HEADER_TEXT: 9,
      },
      headboardOptions: [
        { value: 1, text: 'Imagen' },
        { value: 2, text: 'Texto' },
        { value: 3, text: 'Video' },
        { value: 4, text: 'Archivo adjunto' },
        { value: 5, text: 'Localización' },
      ],
      textFormatParser: new TextFormatParser(),
    }
  },
  computed: {
    urlToken: function () {
      return this.multimediaPreview.url + '&token=' + JSON.parse(localStorage.getItem('user')).token
    },
    messagePreviewAuth: function () {
      return this.multimediaPreview.preview.replace('{COL+}', '<strong>{COL+}</strong>')
    },
    messagePreview: function () {
      if (!this.multimediaPreview.preview.message) {
        return this.$t('Contenido del mensaje').toString()
      }

      return this.textFormatParser.formatHtmlMessage(this.multimediaPreview.preview.message.toString())
    },
    headboard: function () {
      return ''
    },
    hasSelectedHeader: function () {
      return this.multimediaPreview.preview.head_board_option
    },
    hasHeader: function () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.multimediaPreview.preview.head_board_type)
    },
    headerNeedsContentBox: function () {
      return [
        HeadboardOption.options.IMAGE,
        HeadboardOption.options.VIDEO,
        HeadboardOption.options.DOCUMENT,
        HeadboardOption.options.LOCATION,
      ].includes(this.multimediaPreview.preview.head_board_option)
    },
    headerTextContent: function () {
      return JSON.parse(this.multimediaPreview.preview.head_board)
    },

  },
  methods: {
    validateWhatsappHeaderType () {
      return [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ].includes(this.multimediaPreview.preview.head_board_type)
    },
    validateItem () {
      if (this.multimediaPreview.type === 'file') {
        return true
      }
    },
    getLandingPreview () {
      this.htmlLanding = this.multimediaPreview.preview
      this.multimediaPreview.show = false
    },
    getVoicePreview () {
      const params = { path: '/' + this.multimediaPreview.path + '/' + this.multimediaPreview.name }
      CampaignVoiceService.getCreateTemplateData(params).then(
        (response) => {
          this.voicePayload = response.componentsData
          this.voiceTemplate = response.template
          this.multimediaPreview.show = true
        },
        () => { },
      )
    },
    getAudioPreview () {
      this.$nextTick(() => {
        this.$refs.audioPlayer.load()
      })

      this.multimediaPreview.show = true
    },
    getDownloadFile () {
      window.location.href = this.urlToken
    },
    getCapitalizedPath (path) {
      return path[0].toUpperCase() + path.slice(1).toLowerCase()
    },
    showPreviewMultimedia () {
      if (this.validateItem()) {
        if (this.multimediaPreview.fileType === 'landings') {
          this.getLandingPreview()
        } else if (this.multimediaPreview.fileType === 'voz') {
          this.getVoicePreview()
        } else if (this.multimediaPreview.fileType === 'audios') {
          this.getAudioPreview()
        } else {
          this.multimediaPreview.show = true
        }
      }
    },
    setWhatsappType (id) {
      const type = {
        1: this.$t('Solo texto'),
        2: this.$t('Cabecera') + ' + ' + this.$t('Texto'),
        3: this.$t('Texto') + ' + ' + 'Call to action',
        4: this.$t('Cabecera') + ' + ' + this.$t('Texto') + ' + ' + 'Call to action',
        5: this.$t('Texto') + ' + ' + this.$t('Respuestas'),
        6: this.$t('Cabecera') + ' + ' + this.$t('Texto') + ' + ' + this.$t('Respuestas'),
        8: this.$t('Texto') + ' + ' + this.$t('Botones'),
        9: this.$t('Cabecera') + ' + ' + this.$t('Texto') + ' + ' + this.$t('Botones'),
      }

      return type[id] || type[1]
    },
    setWhatsappStatus (name) {
      const status = {
        APPROVED: {
          name: this.$t('Aprobado'),
          color: 'success',
        },
        PENDING: {
          name: this.$t('Pendiente'),
          color: 'warning',
        },
        REJECTED: {
          name: this.$t('Rechazado'),
          color: 'danger',
        },
        DRAFT: {
          name: this.$t('Borrador'),
          color: 'info',
        },
        SENT_TO_VALIDATE: {
          name: this.$t('En revisión'),
          color: '#FFC107',
        },
      }

      return status[name] || status.DRAFT
    },
    handleClick (e) {
      if (e.target.matches('.land-url')) {
        this.$emit('showLanding')
      }

      if (e.target.matches('.form-url')) {
        const formId = e.target.getAttribute('data-form-id')
        this.$emit('showForm', formId)
      }

      if (e.target.matches('.url-url')) {
        const urlId = e.target.getAttribute('data-url-id')
        this.$emit('showUrl', urlId)
      }

      if (e.target.matches('.unsub-url')) {
        this.$emit('showUnsubscribe')
      }
    },
  },
  watch: {
    multimediaPreview: {
      handler: function () {
        this.showPreviewMultimedia()
      },
      deep: true,
    },
  },
  mounted () {
    EventBus.$on('reloadFilePreview', () => {
      this.reloadPreview = false
      setTimeout(() => {
        this.reloadPreview = true
      })
    })
  },
}
